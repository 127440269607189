import { CircularProgress, Grid } from '@mui/material';
import { PaymentMethodsService } from 'api/paymentMethods';
import FormSelect from 'components/common/Form/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import useTranslates from 'utils/translate';
import useDebounce from 'hooks/useDebounce';
import useUpdate from 'hooks/useUpdate';
import { PAYMENT_INFO_DELAY } from './constants';

const createAddressInfo = (pos) => `${pos.address} (${pos.name})`;

const PosInfo = ({
  isDeposit,
  control,
  watch,
  setValue,
  changeActionButtonAvailability,
  paymentMethod,
  getInfoProperties,
}) => {
  const [posInfos, setPosInfos] = useState([]);
  const [posInfoNotFetched, setPosInfoNotFetched] = useState(true);

  const { translate } = useTranslates();
  const selectedCountry = watch('country');
  const selectedCity = watch('city');
  const selectedAddress = watch('address');
  const amount = watch('amount');

  const amountRef = useRef(amount);

  const handler = useCallback(
    async (amount: number, sourceId: number) => {
      const result = await PaymentMethodsService.getPaymentMethodExtraInfo<
        [{ option: { address: string; city: string; country: string; name: string; shopId: number } }]
      >({
        amount,
        infoProperties: getInfoProperties(),
        sourceId,
        type: isDeposit ? 'deposit' : 'withdraw',
      });

      setPosInfos(result.map(({ option }) => option));
      setPosInfoNotFetched(false);
    },
    [getInfoProperties]
  );

  useEffect(() => {
    if (amountRef.current !== amount) {
      setValue('shopId', '');
      setValue('country', '');
      setValue('city', '');
      setValue('address', '');
      changeActionButtonAvailability(false);
    }
    amountRef.current = amount;
  }, [amount]);

  useUpdate(() => {
    if (selectedCountry) {
      setValue('city', '');
      setValue('address', '');
      setValue('shopId', '');
      changeActionButtonAvailability(false);
    }
  }, [selectedCountry]);

  useUpdate(() => {
    if (selectedCity) {
      setValue('address', '');
      setValue('shopId', '');
      changeActionButtonAvailability(false);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedCountry && selectedCity && selectedAddress) {
      const posInfo = posInfos.find(
        (posInfo) =>
          selectedAddress === createAddressInfo(posInfo) && selectedCity === posInfo.city && selectedCountry === posInfo.country
      );
      if (posInfo) {
        setValue('shopId', posInfo.shopId);
        changeActionButtonAvailability(true);
      }
    }
  }, [selectedCountry, selectedCity, selectedAddress, changeActionButtonAvailability, posInfos]);

  const debouncedPaymentMethodExtraInfo = useDebounce(handler, PAYMENT_INFO_DELAY);

  useEffect(() => {
    debouncedPaymentMethodExtraInfo(amount, paymentMethod.method.id);
  }, [amount, paymentMethod.method.id, debouncedPaymentMethodExtraInfo]);

  if (posInfoNotFetched) {
    return (
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    );
  }

  if (!posInfos.length) {
    return (
      <Grid item xs={12}>
        {translate('no_pos_found')}
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <FormSelect
          label={translate('country')}
          name="country"
          required
          options={[...new Set(posInfos.map(({ country }) => country))].map((country) => ({
            id: country,
            name: country,
          }))}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          label={translate('city')}
          name="city"
          required
          options={[
            ...new Set(posInfos.filter(({ country }) => country === selectedCountry).map(({ city }) => city)),
          ].map((city) => ({
            id: city,
            name: city,
          }))}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          label={translate('address')}
          name="address"
          required
          options={[
            ...new Set(posInfos.filter(({ country, city }) => country === selectedCountry && city === selectedCity)),
          ].map(({ address, name }) => ({
            id: createAddressInfo({ address, name }),
            name: createAddressInfo({ address, name }),
          }))}
          control={control}
        />
      </Grid>
    </>
  );
};

export default PosInfo;

import useMount from 'hooks/useMount';
import { useEffect } from 'react';
import useTranslates from 'utils/translate';
import { ACCOUNT_NUMBER_LENGTH, BKASH_B, D17_L, EMAIL_REGEXP, NAGAD_B, PHONE_NUMBER_REGEXP, UPAY } from './constants';
import { Grid } from '@mui/material';
import FormInput from 'components/common/Form/Input';

const ACCOUNT_EMAIL_FIELD = 'accountEmail';
const ACCOUNT_NUMBER_FIELD = 'accountNumber';
const PHONE_NUMBER_FIELD = 'phoneNumber';

const Withdraw = ({
  control,
  watch,
  setError,
  paymentMethod,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
  changeActionButtonAvailability,
}) => {
  const amount = watch('amount');
  const accountNumber = watch(ACCOUNT_NUMBER_FIELD);
  const accountEmail = watch(ACCOUNT_EMAIL_FIELD);
  const phoneNumber = watch(PHONE_NUMBER_FIELD);
  const { translate } = useTranslates();

  useMount(() => {
    changeAmountFieldVisibility(true);
    changeActionButtonVisibility(true);
    changeActionButtonAvailability(false);
  });

  const isBkashB = paymentMethod.method.name === BKASH_B;
  const isNagadB = paymentMethod.method.name === NAGAD_B;
  const isUpay = paymentMethod.method.name === UPAY;
  const isD17L = paymentMethod.method.name === D17_L;
  const needsAccountEmail = isBkashB || isUpay;
  const needsAccountNumber = isBkashB || isNagadB || isUpay;
  const needsPhoneNumber = isD17L;

  const onEmailFieldBlur = () => {
    const email = watch(ACCOUNT_EMAIL_FIELD);
    if (EMAIL_REGEXP.test(email)) {
      setError(ACCOUNT_EMAIL_FIELD, null);
    } else {
      setError(ACCOUNT_EMAIL_FIELD, { message: translate('invalid_email') });
    }
  };

  const onAccountNumberFieldBlur = () => {
    if (!isBkashB) return;

    const accountNumber = watch(ACCOUNT_NUMBER_FIELD);
    if (accountNumber.length === ACCOUNT_NUMBER_LENGTH) {
      setError(ACCOUNT_NUMBER_FIELD, null);
    } else {
      setError(ACCOUNT_NUMBER_FIELD, { message: translate('invalid_account_number') });
    }
  };

  const onPhoneNumberFieldBlur = () => {
    const phoneNumber = watch(PHONE_NUMBER_FIELD);
    if (PHONE_NUMBER_REGEXP.test(phoneNumber)) {
      setError(PHONE_NUMBER_FIELD, null);
    } else {
      setError(PHONE_NUMBER_FIELD, { message: translate('invalid_phone_number') });
    }
  };

  const isValidAccountNumber = !needsAccountNumber || accountNumber?.length === ACCOUNT_NUMBER_LENGTH;
  const isEmailValid = !needsAccountEmail || EMAIL_REGEXP.test(accountEmail);
  const isPhoneNumberValid = !needsPhoneNumber || PHONE_NUMBER_REGEXP.test(phoneNumber);

  useEffect(() => {
    changeActionButtonAvailability(isValidAccountNumber && amount && isEmailValid, isPhoneNumberValid);
  }, [isValidAccountNumber, amount, isEmailValid, isPhoneNumberValid]);

  return (
    <>
      {needsAccountEmail && (
        <Grid item xs={24}>
          <FormInput
            onBlur={onEmailFieldBlur}
            label={translate('account_email')}
            name={ACCOUNT_EMAIL_FIELD}
            required
            control={control}
          />
        </Grid>
      )}
      {needsAccountNumber && (
        <Grid item xs={24}>
          <FormInput
            required
            control={control}
            onBlur={onAccountNumberFieldBlur}
            name={ACCOUNT_NUMBER_FIELD}
            label={translate('account_number')}
          />
        </Grid>
      )}
      {needsPhoneNumber && (
        <Grid item xs={24}>
          <FormInput
            required
            control={control}
            onBlur={onPhoneNumberFieldBlur}
            name={PHONE_NUMBER_FIELD}
            label={translate('phone_number')}
          />
        </Grid>
      )}
    </>
  );
};

export default Withdraw;
